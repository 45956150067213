import React from "react"
import { Controller, Scene } from "react-scrollmagic"
import { Timeline, Tween } from "react-gsap"
import { graphql } from "gatsby"

import MenuLayout from "../components/menulayout"
import SEO from "../components/seo"
import Thumb from "../components/thumb"

const Leistungen = ({ data }) => (
  <MenuLayout>
    <SEO title="Leistungen" />
    <section className="row keyvisual">
      <hr />
      <h1>Visuelle Kommunikation.</h1>
      <h2 className="right">
        Das Interface macht Ihr User Experience Design{" "}
        <span className="note">
          (Research, Planung, Struktur, Workflows, Abläufe, Erlebnisse)
        </span>{" "}
        erst wirklich wahrnehmbar.
      </h2>
    </section>
    <section className="row">
      <Controller>
        <Scene
          offset={0}
          triggerHook={0.3}
          duration={1200}
          pin={true}
          pinSettings={{ pushFollowers: true }}
        >
          {progress => (
            <div style={{ marginTop: -300 }}>
              <Timeline totalProgress={progress} paused>
                <Tween
                  to={{ transform: "translateY(-45%)" }}
                  ease="Power1.easeInOut"
                  duration={900}
                  position="100"
                >
                  <svg
                    version="1.1"
                    id="diagram"
                    x="0px"
                    y="0px"
                    width="512px"
                    height="282px"
                    viewBox="0 0 512 282"
                    enableBackground="new 0 0 512 282"
                  >
                    <Tween
                      from={{
                        opacity: 0,
                      }}
                      duration={200}
                      position="300"
                    >
                      <g id="bg_bg-ui" opacity="0.08">
                        <circle cx="156" cy="141" r="140" stroke="none" />
                      </g>
                    </Tween>
                    <Tween
                      from={{
                        opacity: 0,
                      }}
                      duration={200}
                      position="350"
                    >
                      <g id="bg_bg-ux" opacity="0.04">
                        <circle cx="356" cy="141" r="140" stroke="none" />
                      </g>
                    </Tween>
                    <g id="stroke_stroke-ui">
                      <Tween
                        from={{
                          strokeDasharray: 879.646,
                          strokeDashoffset: 879.646,
                        }}
                        to={{ strokeDashoffset: 0 }}
                        ease="Power4.easeInOut"
                        duration={500}
                        position="0"
                      >
                        <circle
                          cx="156"
                          cy="141"
                          r="140"
                          fill="none"
                          stroke="#000"
                          strokeWidth="0.5"
                        />
                      </Tween>
                    </g>
                    <g id="stroke_stroke-ux">
                      <Tween
                        from={{
                          strokeDasharray: 879.646,
                          strokeDashoffset: 879.646,
                        }}
                        to={{
                          strokeDashoffset: 0,
                        }}
                        ease="Power4.easeInOut"
                        duration={500}
                        position="70"
                      >
                        <circle
                          cx="356"
                          cy="141"
                          r="140"
                          fill="none"
                          stroke="#000"
                          strokeWidth="0.5"
                        />
                      </Tween>
                    </g>
                    <Tween
                      from={{ opacity: 0 }}
                      to={{ opacity: 1 }}
                      duration={200}
                      position="-=200"
                    >
                      <text
                        id="text_text-ui"
                        transform="matrix(1 0 0 1 96.9302 103.6353)"
                        fontFamily="'Avenir'"
                        fontWeight="700"
                        fontSize="44.1453px"
                        letterSpacing="11"
                      >
                        UI
                      </text>
                      <text
                        id="text_text-ui_2"
                        transform="matrix(1 0 0 1 60.0386 143.5827)"
                      >
                        <tspan
                          x="0"
                          y="0"
                          fill="#8C8C8C"
                          fontFamily="'Avenir'"
                          fontSize="20px"
                        >
                          Screen Design
                        </tspan>
                        <tspan
                          x="18.7"
                          y="28"
                          fill="#8C8C8C"
                          fontFamily="'Avenir'"
                          fontSize="20px"
                        >
                          Animation
                        </tspan>
                        <tspan
                          x="3.4"
                          y="56"
                          fill="#8C8C8C"
                          fontFamily="'Avenir'"
                          fontSize="20px"
                        >
                          Visualisierung
                        </tspan>
                      </text>
                    </Tween>
                    <Tween
                      from={{ opacity: 0 }}
                      to={{ opacity: 1 }}
                      duration={200}
                      position="-=140"
                    >
                      <text
                        id="text_text-ux"
                        transform="matrix(1 0 0 1 338.5286 103.6355)"
                        fontFamily="'Avenir'"
                        fontWeight="700"
                        fontSize="44.1453px"
                        letterSpacing="11"
                      >
                        UX
                      </text>
                      <text
                        id="text_text-ux_2"
                        transform="matrix(1 0 0 1 341.7969 143.5827)"
                      >
                        <tspan
                          x="0"
                          y="0"
                          fill="#8C8C8C"
                          fontFamily="'Avenir'"
                          fontSize="20px"
                        >
                          Struktur
                        </tspan>
                        <tspan
                          x="5.7"
                          y="28"
                          fill="#8C8C8C"
                          fontFamily="'Avenir'"
                          fontSize="20px"
                        >
                          Ablauf
                        </tspan>
                        <tspan
                          x="-0.8"
                          y="56"
                          fill="#8C8C8C"
                          fontFamily="'Avenir'"
                          fontSize="20px"
                        >
                          Erlebnis
                        </tspan>
                      </text>
                    </Tween>
                  </svg>
                </Tween>
                <Tween
                  from={{
                    transform: "scaleY(0)",
                  }}
                  to={{
                    transform: "scaleY(1) translateY(40px)",
                  }}
                  ease="Strong.easeOut"
                  duration={400}
                  position="-=250"
                >
                  <div className="vertical-line" />
                </Tween>
              </Timeline>
            </div>
          )}
        </Scene>
        <Scene offset={-400} classToggle="active">
          <figure id="app-icon">
            <div className="app-icon" />
            <figcaption>Ihre App</figcaption>
          </figure>
        </Scene>
      </Controller>
    </section>
    <section id="role" className="row">
      <aside className="col-xs-hidden col-sm-3 card-holder">
        <div className="card">
          <h4>Expertise in</h4>
          <ul>
            <li>User Flow & Wireframes</li>
            <li>Agile&nbsp;/&nbsp;Scrum</li>
            <li>Screen Design</li>
            <li>Interaktionsdesign</li>
            <li>Animationen</li>
            <li>Frontend Code</li>
          </ul>
        </div>
      </aside>
      <div className="col-xs-12 col-sm-6 col-centered home">
        <h3>Rolle & Verantwortlichkeit</h3>
        <p>
          In meiner Rolle des User Interface Designers trage ich dazu bei, dass
          ausformulierte Konzepte und komplexe Abläufe für alle Menschen über
          die visuelle Ebene verständlich vermittelt werden.
        </p>
        <p className="note-parent">
          Ich nutze gerne alle geeigneten Mittel aus der visuellen Kommunikation{" "}
          <span className="sm-hidden note">
            (Farbe, Form, Größe und Beziehung; physische Metaphern wie Licht &
            Schatten; Aufmerksamkeitssteuerung mit Animation){" "}
          </span>
          und lasse fremde und eigene Erfahrungen für ein stimmiges,
          nutzerfreundliches und wohliges Ergebnis einfließen.
          <q className="col-xs-hidden col-sm-5 note" aria-hidden="true">
            <span>
              Mittel wie: Farbe, Form, Größe und Beziehung;
              <br />
              physische Metaphern wie Licht & Schatten;
              <br />
              Aufmerksamkeitssteuerung mit Animation
            </span>
          </q>
        </p>
        <p>
          Damit die Feinheiten unterwegs nicht verloren gehen, unterstütze ich
          Ihr Entwicklungsteam in der Umsetzung und Integration der Designs in
          den Produktions-Code.
        </p>
      </div>
      <aside className="col-xs-12 sm-hidden card-holder" aria-hidden="true">
        <div className="card">
          <h4>Expertise in</h4>
          <ul>
            <li>User Flow & Wireframes</li>
            <li>Agile&nbsp;/&nbsp;Scrum</li>
            <li>Screen Design</li>
            <li>Interaktionsdesign</li>
            <li>Animationen</li>
            <li>Frontend Code</li>
          </ul>
        </div>
      </aside>
    </section>
    <div className="home">
      <section id="projekte" className="row">
        <div className="col-xs-12 col-sm-6 col-centered">
          <h3>Projekte, bei denen diese Expertise angewendet wurde</h3>
        </div>
        <div id="projekte-loop" className="col-xs-12 col-sm-12">
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Thumb node={node} key={node.id} colSpan={4} />
          ))}
        </div>
      </section>
    </div>
  </MenuLayout>
)
export default Leistungen

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { status: { eq: "publish" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            status
            thumb {
              publicURL
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
